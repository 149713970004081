import Representative from "@/models/Representative.model";

export default function Match(data) {
  this.jobOffer = {
    id: data.job_offer.id,
    name: data.job_offer.name,
    availableFrom: data.job_offer.available_from,
    reservedByName: data.job_offer.reserved_by_name,
    reservedAt: data.job_offer.reserved_at,
    reservedMatchId: data.job_offer.reserved_match_id,
    createdAt: data.job_offer.created_at,
    agentName: data.offer_agent_name,
    rate: data.job_offer.rate,
    irregularlyHours: data.job_offer.irregularly_hours || false,
    vehicleNeeded: data.job_offer.vehicle_needed || false,
    remotePossible: data.job_offer.remote_possible || false,
    minimumSalary: data.job_offer.minimum_salary || null,
  }

  this.jobApplication = {
    id: data.job_application.id,
    availability: data.job_application.availability,
    reservedByName: data.job_application.reserved_by_name,
    reservedAt: data.job_application.reserved_at,
    reservedMatchId: data.job_application.reserved_match_id,
    createdAt: data.job_application.created_at,
    agentName: data.application_agent_name,
    rate: data.job_application.rate,
    irregularlyHours: data.job_application.irregularly_hours || false,
    hasVehicle: data.job_application.have_vehicle || false,
    remotePossible: data.job_application.remote_possible || false,
    salaryClaim: data.job_application.salary_claim || null,
  }

  this.candidate = data.job_application.candidate
  this.id = data.id
  if (data.employer_representative) {
    this.employerRepresentative = new Representative(data.employer_representative) || null
  }
  if (data.employer_name) {
    this.employerName = data.employer_name
  }
  this.selections = data.selections

  this.scoreWithInterest = data.score_with_interest
  this.scoreWithoutInterest = data.score_without_interest

  this.implicitScore = data.implicit_score

  this.reservedAt = data.reserved_at || null
  this.reservedById = data.reserved_by_id ||  null
  this.reservedBy = data.reserved_by_name ||  null
  this.effortScale = data.effort_scale || null
}

export function Reservation(data) {
  const name = data.reserved_by
    ? `${data.reserved_by.first_name} ${data.reserved_by.last_name}`
    : ""

  this.reservedAt = data.reserved_at
  this.reservedBy = name
  this.reservedById = (data.reserved_by) ? data.reserved_by.id : null
}
