<template>
  <v-app-bar app color="navbar">
    <div id="title" class="d-flex align-center">
      <h1 class="shrink mr-2 cursor" @click="navigateToHome">Compétences Match</h1>
    </div>
    <page-loader class="ml-4" />
    <v-spacer />
    <v-btn text data-cy="nav-item-matches" @click="navigate('matchesList', true, 'score_without_interest', true, true)">
      {{ $t('navbar.matches') }}
    </v-btn>
    <v-btn text data-cy="nav-item-candidates" @click="navigate('candidatesList')">
      {{ $t('navbar.candidates') }}
    </v-btn>
    <v-btn text data-cy="nav-item-employers" @click="navigate('employersList')">
      {{ $t('navbar.employers') }}
    </v-btn>
    <v-btn text data-cy="nav-item-job-applications" @click="navigate('jobApplications', true)">
      {{ $t('navbar.job_applications') }}
    </v-btn>
    <v-btn text data-cy="nav-item-job-offers" @click="navigate('offers', true)">
      {{ $t('navbar.offers') }}
    </v-btn>
    <UserMenu />
    <div id="notification-wrapper">
      <v-alert
        id="notification-alert"
        v-model="isAlert"
        transition="fade-transition"
        dismissible
        :type="notificationType"
        class="ma-3"
      >
        {{ notificationMessage }}
      </v-alert>
    </div>
  </v-app-bar>
</template>

<script>
import UserMenu from '@/components/header/UserMenu.vue'
import PageLoader from '@/components/common/PageLoader.vue'
import { mapActions, mapState } from 'vuex'
import RouterMixin from '@/mixins/routerMixin'

export default {
  name: 'Navbar',
  components: { UserMenu, PageLoader },
  mixins: [RouterMixin],
  computed: {
    ...mapState('notifications', [
      'alert',
      'notificationMessage',
      'notificationType'
    ]),
    isAlert: {
      get() {
        return this.alert
      },
      set(v) {
        if (!v) {
          this.dismissAlert()
        }
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['dismissAlert']),
    navigateToHome() {
      if (this.$route.path !== '/') this.routerPush('/')
    },
    navigate(routeName, mine = true, defaultSortBy = 'updated_at', defaultSortDesc = true, qualified = true) {
      if (this.$route.name !== routeName) {
        const query = {
          sortBy: defaultSortBy,
          sortDesc: defaultSortDesc
        }

        if (mine) {
          query.mine = true
        }

        if (qualified) {
          query.qualified = true
        }

        this.$router.push({name: routeName, query}).catch(() => {} )
      }
    }
  }
}
</script>

<style scoped>
#title {
  cursor: pointer;
}

#notification-wrapper {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}
</style>
