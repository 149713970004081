/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import Vue from 'vue'
import { getMatch, getMatches, reserveMatch, releaseMatch } from '@/services/matching'
import { Reservation } from "@/models/Match.model";
import {AgentFilterValues} from "@/helpers/agentFilter";
import {QualificationFilterValues} from "@/helpers/qualificationFilter";

export const state = {
  match: null,
  matches: {
    count: null,
    next: null,
    previous: null,
    items: [],
    threshold: null,
  },
  storedFilters: {
    employers: "",
    candidates: "",
    mine: AgentFilterValues.Mine,
    qualified: QualificationFilterValues.Qualified,
  }
}

export const mutations = {
  setMatch(state, match) {
    state.match = match
  },
  setMatches(state, matches) {
    state.matches.items = []
    matches.results.forEach((match, index) => {
      Vue.set(state.matches.items, index, match)
    })
    state.matches.count = matches.count
    state.matches.next = matches.next
    state.matches.previous = matches.previous
    state.matches.threshold = matches.threshold
  },
  setFilters(state, args){
    state.storedFilters.employers = args.employers
    state.storedFilters.candidates = args.candidates
    state.storedFilters.mine = ('mine' in args) ? AgentFilterValues.Mine : AgentFilterValues.All
    state.storedFilters.qualified = ('qualified' in args) ? QualificationFilterValues.Qualified : QualificationFilterValues.All
  },
  updateJobOffer(state, jobOffer) {
    Vue.set(state.match, 'jobOffer', jobOffer)
  },
  setReservation(state, reservation) {
    state.match.reservedById = reservation.reservedById
    state.match.reservedBy = reservation.reservedBy
    state.match.reservedAt = reservation.reservedAt
    state.match.jobOffer.reservedByName = reservation.reservedBy
    state.match.jobApplication.reservedByName = reservation.reservedBy
    if (reservation.reservedAt === null) {
      state.match.jobOffer.reservedMatchId = null
      state.match.jobApplication.reservedMatchId = null
    } else {
      state.match.jobOffer.reservedMatchId = state.match.id
      state.match.jobApplication.reservedMatchId = state.match.id
    }
  }
}

export const actions = {
  getMatch({ commit }, matchId) {
    return new Promise((resolve, reject) => {
      getMatch(matchId)
        .then(match => {
          // The jobOffer
          commit('setMatch', match)
          resolve()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          reject(error)
        })
    })
  },
  getMatches({ state, commit }, args) {
    return new Promise((resolve) => {
      getMatches(args).then(response => {
        commit('setMatches', response.data)
        commit('setFilters', args)
        resolve(state.matches)
      })
    });
  },
  reserveMatch({ commit }, payload) {
    reserveMatch(payload.matchId).then(response => {
      commit('setReservation', new Reservation(response.data))
    })
  },
  releaseMatch({ commit }, matchId) {
    releaseMatch(matchId).then(response => {
      commit('setReservation', new Reservation(response.data))
    })
  }
}

export const getters = {
  getMatchImplicitActivities(state) {
    return [...state.match.selections.implicit].sort(
      (a, b) => b.frequency - a.frequency
    )
  },

  getMatchExplicitActivities(state) {
    return [...state.match.selections.explicit].sort(
      (a, b) => b.frequency - a.frequency
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
